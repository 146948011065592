<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <g transform="translate(-0.09 -0.193)" clip-path="url(#clip-path)">
      <path
        d="M21.6,4H4.926A.926.926,0,0,0,4,4.926V21.6a.917.917,0,0,0,1.575.649l3.613-3.428H21.6a.926.926,0,0,0,.926-.926V4.926A.926.926,0,0,0,21.6,4m-.926,12.97H8.447l-.556.51L5.853,19.425V5.853H20.676Z"
        transform="translate(-3.006 -3.006)"
        fill="currentColor"
      />
      <path
        d="M14.926,15.853h7.412a.926.926,0,0,0,0-1.853H14.926a.926.926,0,0,0,0,1.853"
        transform="translate(-8.373 -8.373)"
        fill="currentColor"
      />
      <path
        d="M14.926,23.853h3.706a.926.926,0,1,0,0-1.853H14.926a.926.926,0,1,0,0,1.853"
        transform="translate(-8.373 -12.668)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
